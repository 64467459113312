// import aos
import AOS from "aos";

export default function init() {
  AOS.init({
    disable: "mobile",
    once: true,
  });
  jQuery("#js-menu-open, #js-menu-close").on("click", function () {
    jQuery("#js-menu").toggleClass("-left-full");
    jQuery("#js-menu").toggleClass("left-0");
  });

  jQuery("#js-mobile-marternite-button").on("click", function () {
    jQuery("#js-mobile-marternite-content").slideToggle(300);
    jQuery(this).toggleClass("active");
    jQuery(".list-menu").toggleClass("pt-32");
    jQuery(".list-menu").toggleClass("pt-20");
  });

  jQuery("#js-search").on("click", function () {
    // aadd overflow hidden to body if search is open
    // place cursor in  #js-search-input in js no jquery
    document.getElementById("js-search-input").focus();

    jQuery("body").toggleClass("overflow-hidden");
    jQuery("#global-search").toggleClass("opacity-0");
    jQuery("#global-search").toggleClass("invisible");
    jQuery("#global-search").toggleClass("visible");
    jQuery("#global-search").toggleClass("opacity-100");
    // jQuery("#global-search").toggleClass("backdrop-blur");
    //search-palette
    jQuery("#search-palette").toggleClass("opacity-0");
    jQuery("#search-palette").toggleClass("opacity-100");
    jQuery("#search-palette").toggleClass("scale-100");
    jQuery("#search-palette").toggleClass("scale-95");
  });
  // escape to close search only if search is open
  jQuery(document).keyup(function (e) {
    if (e.key === "Escape" && jQuery("#global-search").hasClass("visible")) {
      jQuery("body").toggleClass("overflow-hidden");

      jQuery("#global-search").toggleClass("opacity-0");
      jQuery("#global-search").toggleClass("invisible");
      jQuery("#global-search").toggleClass("visible");
      jQuery("#global-search").toggleClass("opacity-100");
      //search-palette
      jQuery("#search-palette").toggleClass("opacity-0");
      jQuery("#search-palette").toggleClass("opacity-100");
      jQuery("#search-palette").toggleClass("scale-100");
      jQuery("#search-palette").toggleClass("scale-95");
    }
  });

  var ajaxSearch = function () {
    var searchTerm = jQuery("#js-search-input").val();
    jQuery.ajax({
      url: "/wp/wp-admin/admin-ajax.php",
      type: "POST",
      data: {
        action: "global_search",
        search_term: searchTerm,
      },
      beforeSend: function () {
        jQuery("#search-results").empty();
        jQuery(".loader").show();
      },
      success: function (data) {
        jQuery(".loader").hide();
        jQuery("#search-results").html(data);
      },
    });
    return false;
  };

  let timer = null;
  jQuery("#js-search-input").keyup(function (e) {
    clearTimeout(timer);
    timer = setTimeout(ajaxSearch, 500);
  });
}
